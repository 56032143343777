import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './helpers';

const { REACT_APP_API_URL_SERVICING } = process.env;

const api = createApi({
  reducerPath: 'networks',
  baseQuery: fetchBaseQuery({
    // we have /api as part of the env var, but this endpoint uniquely does not use it
    baseUrl: REACT_APP_API_URL_SERVICING.replace(/\/api$/, '') + '/v2/networks',
    prepareHeaders,
  }),
  endpoints: builder => ({
    networks: builder.query({
      query: () => ({
        url: `/`,
      }),
    }),
  }),
});

export default api;

export const { useNetworksQuery } = api;
