import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { DsLoader } from '@tokenyico/design-system/dist/reactJS';
import { I18nextProvider } from 'react-i18next';
import './i18n';

import { applyPolyfills, defineCustomElements } from '@tokenyico/design-system/loader';

import '@tokenyico/design-system/dist/design-system/design-system.css';
import 'app/global.scss';
import i18next from 'i18next';
import store from './app/store';
import { Provider } from 'react-redux';
import { injectProviders } from '@tokenyico/vueit/dist';

// import { makeMockServer } from './services/mocks';
const theme = require(`assets/themes/${process.env.REACT_APP_THEME_NAME}/themeConfig.json`);
const logo = require(`assets/themes/${process.env.REACT_APP_THEME_NAME}/logo.png`);

applyPolyfills().then(() => {
  defineCustomElements();
});

/**
 ** Init: Client UI theme
 **/
const html = document.getElementsByTagName('html')[0];
Object.keys(theme).forEach(key => {
  if (key === 'title') document.title = 'Servicing - ' + theme.title;
  else html.style.setProperty(`--${key}`, theme[key]);
});

if (process.env.REACT_APP_ENV !== 'production') {
  // makeMockServer({ environment: 'development' });
}

const App = lazy(() => import('./app/App'));

const env = process.env.REACT_APP_ENV || process.env.REACT_APP_DEPLOY_ENV;

const Loading = () => (
  <div className="app-loading">
    <DsLoader version="dots" size="2.5rem" color="var(--primary)" />
  </div>
);

if (env !== 'development') {
  Sentry.init({
    dsn: 'https://e6f3f5147945485e858da6bd4345ad5d@sentry.io/1553736',
    environment: process.env.REACT_APP_DEPLOY_ENV,
    release: `servicing-issuer@${process.env.REACT_APP_RELEASE}`,
  });
  Sentry.configureScope(scope => {
    scope.setTag('token_trader', process.env.REACT_APP_API_URL_TOKEN_TRADER);
    scope.setTag('user_pool', process.env.REACT_APP_AWS_USER_POOL_ID);
    scope.setTag('servicing_api', process.env.REACT_APP_API_URL_SERVICING);
    scope.setTag('release_date', process.env.REACT_APP_BUILD_DATE);
  });
}
injectProviders({
  projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
  metadata: {
    name: theme.title,
    description: 'An application for managing and issuing digital tokens.',
    url: window.location.host, // origin must match your domain & subdomain
    icons: [logo],
  },
  web3ModalThemeVariables: {
    // https://docs.reown.com/appkit/vue/core/theming
    '--w3m-accent': 'var(--primary)',
    '--w3m-color-mix': 'var(--primary)',
    '--w3m-color-mix-strength': 8,
    '--w3m-border-radius-master': '0.5px',
    '--w3m-z-index': 9999,
  },
});

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </Suspense>,
  document.getElementById('root'),
);
