import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './helpers';
import store from './';

const { REACT_APP_API_URL_SERVICING } = process.env;

const api = createApi({
  reducerPath: 'redemptions',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_SERVICING + '/',
    prepareHeaders,
  }),
  endpoints: builder => ({
    allRedemptions: builder.query({
      query: ({ tokenId }) => ({
        url: `tokens/${tokenId}/redemptions`,
      }),
    }),
    redemptionsOrders: builder.query({
      query: ({ tokenId, ...request }) => ({
        url: `tokens/${tokenId}/redemptions/orders`,
        params: {
          ...request,
        },
      }),
    }),
    confirmOrder: builder.mutation({
      query: ({ redemptionId, redemptionOrderIds }) => ({
        method: 'POST',
        url: `redemptions/${redemptionId}/orders/confirm-payment`,
        body: {
          redemptionId,
          redemptionOrderIds,
        },
      }),
    }),
    batchOrderActions: builder.mutation({
      query: ({ redemptionId, redemptionOrders, action }) => ({
        method: 'POST',
        url: `redemptions/${redemptionId}/orders/actions/${action}`,
        body: {
          redemptionOrders,
          ...(action !== 'cancel'
            ? {
                redemptionId,
              }
            : {}),
        },
      }),
    }),
    redemptionsOrder: builder.query({
      query: ({ redemptionId, orderId }) => ({
        url: `redemptions/${redemptionId}/orders/${orderId}`,
      }),
    }),
    exchangeRates: builder.query({
      query: ({ redemptionId }) => ({
        url: `redemptions/${redemptionId}/exchange-rates`,
      }),
    }),
    sendOrderActionTxHash: builder.mutation({
      query: ({ redemptionId, redemptionOrdersActionId, txHash, taskId }) => ({
        url: `redemptions/${redemptionId}/orders/actions/${redemptionOrdersActionId}/transactions`,
        method: 'POST',
        body: {
          txHash,
          taskId,
        },
      }),
    }),
    redemptionOrdersActions: builder.query({
      query: ({ redemptionId, limit, status }) => ({
        url: `redemptions/${redemptionId}/orders/actions`,
        params: {
          limit,
          status,
        },
      }),
    }),
  }),
});

export default api;

export const {
  useAllRedemptionsQuery,
  useLazyAllRedemptionsQuery,
  useLazyRedemptionsOrdersQuery,
  useConfirmOrderMutation,
  useBatchOrderActionsMutation,
  useLazyRedemptionsOrderQuery,
  useLazyExchangeRatesQuery,
  useSendOrderActionTxHashMutation,
  useLazyRedemptionOrdersActionsQuery,
} = api;

export const getRedemptionOrdersCSVURL = async ({ redemptionId }) => {
  const queryString = new URLSearchParams({
    jwt: store.getState().aws.jwt,
  });

  const url = new URL(
    `${REACT_APP_API_URL_SERVICING}/redemptions/${redemptionId}/redemption-orders/csv`,
  );
  url.search = queryString;

  return url.toString();
};
